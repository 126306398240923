<template>
  <div class="news_details">
    <div class="item-box">
      <h2>{{uewsItem.title}}</h2>
      <div class="item-right">
        <!-- 标签 -->
        <div class="tag" v-if="tagList.length > 0">
          <el-tag type="info" v-for="(tag,i) in tagList" :key="i">{{tag}}</el-tag>
        </div>
        <!-- 详情内容 -->
        <div style="font-size:14px; line-height: 22px;" v-html="contentHtml"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { getNewsById } from '@/api/infoRequest.js'
export default {
  data () {
    return {
      uewsId: null, // 资讯id
      uewsItem: {}, // 资讯详情
      contentHtml: '', // 替换过的html
      tagList: [] // 标签数据
    }
  },
  methods: {
    // 获取资讯详情
    async getNewsById () {
      try {
        const res = await getNewsById({ newsId: this.uewsId })
        this.uewsItem = res.data
        this.contentHtml = this.maximg(this.uewsItem.contentHtml)
        if (this.uewsItem.label) {
          this.tagList = this.uewsItem.label && this.uewsItem.label.split('-')
        }
      } catch (error) {
        return this.err('获取资讯详情失败')
      }
    },
    // 处理过大的图片样式
    maximg (src) {
      return src.replace(/<img/g, '<img style="max-width: 100%;"')
    }
  },
  created () {
    this.uewsId = this.$route.query.newsId
    this.getNewsById()
  }
}
</script>
<style lang="less" scoped>
.news_details {
  min-height: calc(100vh - 76px);
  background-color: #f5f7fb;
  .item-box {
    // display: flex;
    // flex-wrap: wrap;
    width: var(--width);
    margin: 0 auto;
    padding: 40px;
    h2 {
      width: 100%;
      height: 40px;
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 20px;
    }
    .item-right {
      .el-tag {
        margin: 0 15px 20px 0;
        background-color: #f5f7fb;
      }
    }
  }
}
</style>